
import { Component, Prop, Vue } from 'vue-property-decorator'

import { RouteName } from '@/modules/events/config'
import { SearchBar } from '@/modules/events/components/organisms/SearchBar'
import { AmplitudeType, AppAmplitudeEvent, IAmplitude } from '@/shared/services/amplitude'

import { Locale, LocaleKey } from '@/shared/contracts'
import { IRootService, RootServiceType } from '@/modules/root/services/root'
import { IModal, ModalServiceType } from '@/core/services/modal'
import { RootModuleModalsCollection } from '@/modules/root/config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'NavbarDesktop',
  components: { SearchBar }
})
export class NavbarDesktop extends Vue {
  @Prop({ type: Object, required: false, default: () => ({}) })
  protected readonly locales!: Locale

  @Prop({ type: String, required: false})
  protected readonly selectedLocale?: LocaleKey

  protected readonly amplitude =
    this.$container.get<IAmplitude>(AmplitudeType)

  protected readonly rootService =
    this.$container.get<IRootService>(RootServiceType)

  protected readonly modalService =
    this.$container.get<IModal>(ModalServiceType)

  public searchPhrase: string = ''

  public get locale (): string {
    return this.selectedLocale ? this.$t(`locales.${this.selectedLocale}`).toString() : '---'
  }

  public get dropdownItems (): any[] {
    return Object.entries(this.locales).map(([key, value]) => {
      return {
        label: value,
        action: (closeCb: CallableFunction) => {
          this.$i18n.locale = key.toLocaleLowerCase()
          this.$emit('onLocaleChange', key)
          this.amplitude.emit(AppAmplitudeEvent.ON_OPEN_CHANGE_LANGUAGE)
          window.location.reload()
          closeCb()
        }
      }
    })
  }

  public get selectedCity (): string {
    return this.rootService.getProperty('city') ?? '---'
  }

  public async goToMap (): Promise<void> {
    await this.$router.push({ name: `events.${RouteName.Map}` })
  }

  public async goToHome (): Promise<void> {
    this.amplitude.emit(AppAmplitudeEvent.ON_CLICK_LOGO)
    await this.$router.push('/')
  }

  public openCitySelectorModal (): void {
    this.modalService.show(RootModuleModalsCollection.CityChoices)
  }
}
export default NavbarDesktop

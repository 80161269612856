
import { Component, Prop, Vue } from 'vue-property-decorator'

import { SearchBar } from '@/modules/events/components/organisms/SearchBar'

import { Locale, LocaleKey } from '@/shared'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'NavbarMobile', components: { SearchBar } })
export class NavbarMobile extends Vue {
  @Prop({ type: Object, required: false, default: () => ({}) })
  protected readonly locales!: Locale

  @Prop({ type: String, required: false})
  protected readonly selectedLocale?: LocaleKey

  public isOpen: boolean = false
  public searchPhrase: string = ''

  public get locale (): string {
    return this.selectedLocale ? this.$t(`locales.${this.selectedLocale}`).toString() : '---'
  }

  public get localeChoices (): any[] {
    return Object.entries(this.locales).map(([key, value]) => {
      return {
        label: value,
        key: key
        }
    })
  }

  public toggleNavbarMenu (): void {
    this.isOpen = !this.isOpen
  }

  public async goToHome (): Promise<void> {
    await this.$router.push('/')
  }

  public async goToSavedEvents (): Promise<void> {
    this.isOpen = false
    await this.$router.push({
      name: 'favourites.favourites'
    })
  }

  public setLocale (key: string): void {
    this.$i18n.locale = key.toLocaleLowerCase()
    this.$emit('onLocaleChange', key)
  }
}

export default NavbarMobile
